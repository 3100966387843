import { Box, Button, Typography } from "@mui/material";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import "./styles.css";

export function PrintComponentProductIDS({
  productIDs,
}: {
  productIDs: number[];
}) {
  const contentToPrint = useRef(null);
  const handlePrint = useReactToPrint({
    bodyClass: "print",
    removeAfterPrint: true,
    copyStyles: true,
  });

  return (
    <Box
      sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}
    >
      <Button
        onClick={() => {
          handlePrint(null, () => contentToPrint?.current);
        }}
        variant="contained"
        sx={{ mr: 2 }}
      >
        PRINT
      </Button>
      <Box ref={contentToPrint} className="print-preview" width={"100%"}>
        <Box
          width={"100%"}
          display={"flex"}
          justifyContent={"flex-start"}
          flexDirection={"column"}
          alignItems={"center"}
          maxHeight={500}
          height={500}
          flexWrap={"wrap"}
          mt={1}
          mb={1}
          gap={1}
        >
          {productIDs.map((item, index) => (
            <Typography fontSize={18} key={index}>
              ID: {item}
            </Typography>
          ))}
        </Box>
      </Box>
    </Box>
  );
}
